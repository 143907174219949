<template>
  <div class="d-flex justify-space-between app-calendar-header">
    <div
      v-for="(item, key) in days"
      :key="key"
      class="app-calendar-header-col"
    >
      <span class="d-flex align-center h-100 text-overline lh-1 px-1">{{ item }}</span>
    </div>
    <app-calendar-summary>
      <div></div>
    </app-calendar-summary>
  </div>
</template>

<script>
  import AppCalendarSummary from "./AppCalendarSummary";

  export default {
    name: "AppCalendarHeader",
    components: {
      AppCalendarSummary
    },
    props: {
      startDate: {
        type: [Object, Number],
        default: function () {
          return this.$moment().tz('UTC').startOf('isoweek');
        }
      }
    },
    data () {
      return {
        days: [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье'
        ]
      }
    },
    computed: {

    },
    mounted () {

    },
    methods: {

    }
  }
</script>

<style scoped lang="scss">
  .app-calendar-header {
    min-width: 1200px;
    //overflow-y: scroll;
    //border-bottom: 1px solid #ccc;
    //background-color: #eaebec;
    background-color: var(--v-secondary-base);
    color: var(--v-primary-base);
    //border-top: 1px solid var(--v-primary-base);
    //border-bottom: 1px solid var(--v-primary-base);
    height: $app-calendar-header-height;
    //min-height: $app-calendar-header-height;
    position: sticky;
    top: 0;
    z-index: 5;
  }
  .app-calendar-header-col {
    //border-right: 1px solid var(--v-primary-base);
    //width: $app-calendar-col-width;
  }
  .app-calendar-header-col:first-child {
    border-left: none
  }
</style>
