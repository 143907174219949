<template>
  <div class="app-calendar-col">
    <slot>
      <div
        v-if="!!$slots['header']"
        class="app-calendar-col-header d-flex align-center text-caption pa-1"
      >
        <slot name="header" />
      </div>
      <div
        class="app-calendar-col-body pt-1"
        style="min-height: 100px"
      >
        <slot name="body">
          <div
            v-if="showPlans"
            class="pb-1 overflow-x-hidden"
          >
            <div
              v-if="enableSchoolFunctions"
            >
              <div
                v-if="textareaDisabled"
                class="app-calendar-plan text-caption lh-1 pl-2 pr-1 py-1 mb-1"
                v-html="(compDescription && compDescription !== '') ? compDescription.replace(/(?:\r\n|\r|\n)/g, '<br>') : 'Плана нет'"
              ></div>
              <v-textarea
                v-else
                v-model="compDescription"
                outlined
                placeholder="Добавьте план"
                dense
                auto-grow
                hide-details
                row-height="12"
                rows="7"
                tile
                class="text-caption lh-1 lh-child-1 mb-1 ko-style-textarea"
              />
              <div
                class="d-flex justify-end mb-2"
              >
                <v-btn
                  v-if="textareaDisabled"
                  class="ml-auto"
                  x-small
                  outlined
                  color="primary"
                  tile
                  :disabled="isLoading"
                  @click="editPlan"
                >
                  Изменить
                </v-btn>
                <v-btn
                  v-else
                  class="ml-auto"
                  x-small
                  outlined
                  color="success"
                  tile
                  :disabled="isLoading"
                  @click="savePlan"
                >
                  Сохранить
                </v-btn>
              </div>
            </div>

            <div
              v-else
            >
              <div
                v-if="plans.length > 0"
              >
                <div
                  v-for="plan in plans"
                  :key="plan.id"
                  class="app-calendar-plan text-caption lh-1 pl-2 pr-1 py-2"
                  :class="{ 'd-none': plan.description === null }"
                  v-html="getDescription(plan)"
                >
                </div>
              </div>
              <div
                v-else
                class="app-calendar-plan text-caption lh-1 pl-2 pr-1 py-2"
              >
                Планов нет
              </div>
            </div>
          </div>
          <div v-if="showMoves">
            <app-calendar-item
              v-for="(item, key) in moves"
              :key="key"
              :item="item"
              :enable-laps="enableLaps"
              type="move"
              @deleteMove="handleDeleteMove"
            />
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
  import axios from 'axios'
  import AppCalendarItem from "./AppCalendarItem"

  export default {
    name: "AppCalendarCol",
    components: {
      AppCalendarItem
    },
    props: {
      userId: {
        type: [Number],
        default: function () {
          return null;
        }
      },
      plans: {
        type: [Array],
        default: function () {
          return [];
        }
      },
      moves: {
        type: [Array],
        default: function () {
          return [];
        }
      },
      enableSchoolFunctions: {
        type: [Boolean],
        default: false
      },
      enableLaps: {
        type: [Boolean],
        default: false
      },
      date: {
        type: [Object],
        default: function () {
          return null
        }
      },
      showPlans: {
        type: [Boolean],
        default: true
      },
      showMoves: {
        type: [Boolean],
        default: true
      },
    },
    data() {
      return {
        localPlan: {
          description: '',
          id: -1,
          set: false
        },
        isLoading: false,
        textareaDisabled: true,
        localDescription: '',
      }
    },
    computed: {
      compDescription: {
        get: function () {
          return this.plans.length > 0 ? this.plans[0].description : this.localPlan.description;
        },
        set: function (newValue) {
          if (this.plans.length > 0) {
            this.plans[0].description = newValue;
          } else {
            this.localPlan.description = newValue;
          }
        }
      }
    },
    mounted () {
      if (this.plans.length > 0) {
        //this.localPlan.description = this.plans[0].description;
      }
    },
    methods: {
      handleDeleteMove (move) {
        this.$emit('deleteMove', move);
      },
      getDescription (plan) {
        if (plan.description !== null) {
          return plan.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }
        return 'Пустой план';
      },
      editPlan () {
        this.textareaDisabled = false;
      },
      savePlan () {
        this.isLoading = true;
        axios({
          url: '/api/plans/add',
          method: 'POST',
          data: {
            user_id: this.userId,
            description: this.plans.length > 0 ? this.plans[0].description : this.localPlan.description,
            start: this.date.clone().tz(this.$moment.tz.guess()).format("YYYY-MM-DD") + " 00:00:00",
            id: this.plans.length > 0 ? this.plans[0].id : this.localPlan.id
          }
        })
          .then(
            (res) => {
              if (this.plans.length > 0) {
                this.plans[0].description = res.data.description;
              } else {
                // план сохраняется в массив, нужно как-то избавиться от локалПлан
                this.$emit('addPlan', res.data);
                this.localPlan.description = res.data.description;
                this.localPlan.id = res.data.id;
               // this.localPlan.set = true;
              }
              //this.localPlan = res.data;
              // console.log(res);
            })
          .catch(
            (err) => {
              console.log(err);
            })
          .finally(
            () => {
              this.isLoading = false;
              this.textareaDisabled = true;
            })

      }
    }
  }
</script>

<style scoped lang="scss">
  .app-calendar-col {
    //background-color: #f9f9f9;
    width: $app-calendar-col-width;
  }
  .app-calendar-col-header {
    color: var(--v-secondary-base);
    background-color: var(--v-primary-base);
    border-bottom: none;
    //border-right: 3px solid var(--v-secondary-base);
    //border-left: 2px solid var(--v-secondary-base);
    height: $app-calendar-header-height;
    position: sticky;
    top: $app-calendar-header-height;
    z-index: 3;
  }
  .app-calendar-col-body {
    //border-right: 1px solid var(--v-secondary-base);
    //padding-right: 2px !important;
    //padding-left: 2px !important;
  }
  .app-calendar-plan {
    border: 1px solid var(--v-primary-base);
    min-height: 100px;
    word-break: break-word;
    &+.app-calendar-plan {
      margin-top: 4px!important;
    }

    &.text-caption {
      letter-spacing: 0!important;
    }
  }
</style>
