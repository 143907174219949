<template>
  <div class="app-calendar-summary">
    <slot>
      <div
        v-if="!!$slots['header']"
        class="app-calendar-summary-header d-flex align-center text-overline pa-1"
      >
        <slot name="header" />
      </div>
      <div class="app-calendar-summary-body py-2 primary--text">
        <slot name="body">
          <div
            v-if="sumValues.runDistance > 0"
            class="mb-1"
          >
            <div class="row no-gutters align-center">
              <div class="col shrink">
                <v-icon
                  color="primary"
                  class="mr-1"
                >
                  mdi-run
                </v-icon>
              </div>
              <div class="col">
                {{ getTimeFormatted(sumValues.runTime) }}

              </div>
              <div class="col">
                {{ Math.trunc(sumValues.runDistance / 100) / 10 }} км

              </div>
              <div class="col">
                +{{ sumValues.runClimb }} м
              </div>
            </div>
          </div>

          <div
            v-if="sumValues.bikeDistance > 0"
            class="mb-1"
          >
            <div class="row no-gutters align-center">
              <div class="col shrink">
                <v-icon
                  color="primary"
                  class="mr-1"
                >
                  mdi-bike
                </v-icon>
              </div>
              <div class="col">
                {{ getTimeFormatted(sumValues.bikeTime) }}

              </div>
              <div class="col">
                {{ Math.trunc(sumValues.bikeDistance / 100) / 10 }} км

              </div>
              <div class="col">
                +{{ sumValues.bikeClimb }} м
              </div>
            </div>
          </div>

          <div
            v-if="sumValues.skiDistance > 0"
            class="mb-1"
          >
            <div class="row no-gutters align-center">
              <div class="col shrink">
                <v-icon
                  color="primary"
                  class="mr-1"
                >
                  mdi-ski
                </v-icon>
              </div>
              <div class="col">
                {{ getTimeFormatted(sumValues.skiTime) }}

              </div>
              <div class="col">
                {{ Math.trunc(sumValues.skiDistance / 100) / 10 }} км

              </div>
              <div class="col">
                +{{ sumValues.skiClimb }} м
              </div>
            </div>
          </div>

          <div
            v-if="sumValues.hikingDistance > 0"
            class="mb-1"
          >
            <div class="row no-gutters align-center">
              <div class="col shrink">
                <v-icon
                  color="primary"
                  class="mr-1"
                >
                  mdi-hiking
                </v-icon>
              </div>
              <div class="col">
                {{ getTimeFormatted(sumValues.hikingTime) }}

              </div>
              <div class="col">
                {{ Math.trunc(sumValues.hikingDistance / 100) / 10 }} км

              </div>
              <div class="col">
                +{{ sumValues.hikingClimb }} м
              </div>
            </div>
          </div>

          <div
            v-if="sumValues.yogaTime > 0"
            class="mb-1"
          >
            <div class="d-flex align-center">
              <v-icon
                color="primary"
                class="mr-1"
              >
                self_improvement
              </v-icon> {{ getTimeFormatted(sumValues.yogaTime) }}
            </div>
          </div>

          <div
            v-if="sumValues.workoutTime > 0"
            class="mb-1"
          >
            <div class="d-flex align-center">
              <v-icon
                color="primary"
                class="mr-1"
              >
                fitness_center
              </v-icon> {{ getTimeFormatted(sumValues.workoutTime) }}
            </div>
          </div>

          <div
            v-if="sumValues.allTime > 0"
            class="mb-1"
          >
            <v-divider class="mb-1 primary" />
            <div class="d-flex align-center">
              <v-icon
                color="primary"
                class="mr-1"
              >
                mdi-clock-time-four-outline
              </v-icon> {{ getTimeFormatted(sumValues.allTime) }}
            </div>
          </div>

          <div
            v-else
            class="mb-1"
          >
            <div class="d-flex align-center">
              <v-icon
                color="primary"
                class="mr-1"
              >
                mdi-clock-time-four-outline
              </v-icon> 0ч. 0м.
            </div>
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
  export default {
    name: "AppCalendarSummary",
    props: {
      sumValues: {
        type: [Object],
        default: function () {
          return {};
        }
      }
    },
    methods: {
      getTimeFormatted (seconds, hours = true) {
        let h = 0;
        let m = 0;
        //let s = 0;
        if ((hours) && (seconds >= 3600)) {
          h = Math.trunc(seconds / 3600);
        }
        if (seconds >= 60) {
          m = Math.trunc((seconds - h * 3600) / 60);
        }
        //s = Math.trunc(seconds - h * 3600 - m * 60);
        return (h + 'ч. ') + (m + 'м. ');
        //return (h > 0 ? h + 'ч. ' : '') + (h > 0 || m > 0 ? m + 'м. ' : '') + (h === 0 ? s + 'с. ' : '');
        //return (hours ? (h + ':') : '') + (m > 9 ? m : ('0' + m)) + ':' + (s > 9 ? s : ('0' + s))
      }
    }
  }
</script>


<style scoped lang="scss">
  .app-calendar-summary {
    // background-color: #f9f9f9;
    color: var(--v-primary-base);
    width: $app-calendar-summary-width;
  }
  .app-calendar-summary-header {
    background-color: var(--v-primary-base);
    color: var(--v-secondary-base);
    //border-right: 3px solid var(--v-secondary-base);
    //border-left: 2px solid var(--v-secondary-base);
    height: $app-calendar-header-height;
    position: sticky;
    top: $app-calendar-header-height;
    z-index: 3;
  }
  .app-calendar-summary-body {
    //border-left: 2px solid var(--v-secondary-base);
    //border-right: 3px solid var(--v-secondary-base);
    font-size: 14px;
    position: sticky;
    z-index: 2;
    color: var(--v-primary-base);
    top: $app-calendar-header-height * 2;
  }
</style>
