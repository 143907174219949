<template>
  <div class="app-calendar-item">
    <slot>
      <div
        v-if="item"
        class="app-calendar-item-default mb-1 pa-2"
      >
        <div class="d-flex">
          <v-icon color="primary">
            {{ iconComp }}
          </v-icon>
          <v-icon
            v-if="item.hidden_for_school"
            color="primary"
            x-small
            class="app-calendar-item-hiddenv mb-auto"
          >
            mdi-lock
          </v-icon>
          <v-menu
            bottom
            left
            nudge-bottom="24"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-auto"
                icon
                small
                :disabled="isDeletingMove || isHidingMove"
                :loading="isDeletingMove || isHidingMove"
                v-on="on"
              >
                <v-icon
                  v-if="!enableLaps"
                  color="primary"
                >
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <template>
              <v-list
                dense
                class="pa-0"
              >
                <v-list-item
                  @click="toggleHide(item)"
                >
                  {{ item.hidden_for_school ? 'Сделать публичной' : 'Сделать приватной' }}
                </v-list-item>
                <v-list-item
                  @click="deleteMove(item)"
                >
                  Удалить
                </v-list-item>
              </v-list>
            </template>
          </v-menu>
        </div>
        <div
          class="text-caption font-weight-bold lh-1 my-2"
        >
          <a
            v-if="stravaLinkComp !== '#'"
            :href="stravaLinkComp"
            target="_blank"
          >
            {{ item.name }}
          </a>
          <span
            v-else
          >
            {{ item.name }}
          </span>
        </div>
        <div
          v-if="distComp !== 0"
          class="lh-1"
        >
          <v-icon
            color="primary"
            small
          >
            mdi-vector-line
          </v-icon> <span class="text-caption">{{ distComp + ' км' }}</span>
        </div>
        <div class="lh-1">
          <v-icon
            color="primary"
            small
          >
            mdi-timer-outline
          </v-icon> <span class="text-caption">{{ timeComp }}</span>
        </div>
        <div
          v-if="elevComp !== 0"
          class="lh-1"
        >
          <v-icon
            color="primary"
            small
          >
            mdi-trending-up
          </v-icon> <span class="text-caption">{{ elevComp + ' м' }}</span>
        </div>
        <div
          v-if="heartrateComp !== '-'"
          class="lh-1"
        >
          <v-icon
            color="primary"
            small
          >
            mdi-heart-outline
          </v-icon> <span class="text-caption">{{ heartrateComp }}</span>
        </div>
        <div
          v-if="enableLaps && lapsComp && lapsComp.length > 1"
          class="lh-1"
        >
          <v-expansion-panels
            flat
            class="app-calendar-item-laps"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                class="text-caption font-weight-bold py-2 px-0"
              >
                Круги
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="overflow-x-visible"
              >
                <v-simple-table
                  dense
                >
                  <thead>
                    <tr
                      class="text-caption"
                    >
                      <th>
                        <v-icon
                          color="primary"
                          small
                        >
                          mdi-vector-line
                        </v-icon>
                      </th>
                      <th>
                        <v-icon
                          color="primary"
                          small
                        >
                          mdi-timer-outline
                        </v-icon>
                      </th>
                      <th>
                        <v-icon
                          color="primary"
                          small
                        >
                          mdi-speedometer
                        </v-icon>
                      </th>
                      <th>
                        <v-icon
                          color="primary"
                          small
                        >
                          mdi-trending-up
                        </v-icon>
                      </th>
                      <th>
                        <v-icon
                          color="primary"
                          small
                        >
                          mdi-heart-outline
                        </v-icon>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(lap, key) in lapsComp"
                      :key="key"
                      class="text-caption"
                    >
                      <td>
                        {{ Math.trunc(lap.distance / 10) / 100 }}
                      </td>
                      <td>
                        {{ getTimeFormatted(lap.elapsed_time, false) }}
                      </td>
                      <td>
                        {{ getTimeFormatted(Math.trunc(lap.elapsed_time / (lap.distance / 1000)), false) }}
                      </td>
                      <td>
                        {{ lap.total_elevation_gain }}
                      </td>
                      <td>
                        {{ lap.average_heartrate ? Math.trunc(lap.average_heartrate) : "-" }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapActions } from "vuex";

  export default {
    name: "AppCalendarItem",
    props: {
      item: {
        type: [Object],
        default: function () {
          return null;
        }
      },
      type: {
        type: [String],
        default: function () {
          return 'move';
        }
      },
      enableLaps: {
        type: [Boolean],
        default: false
      }
    },
    data: () => {
      return {
        isDeletingMove: false,
        isHidingMove: false,
      }
    },
    computed: {
      iconComp () {
        if (this.item) {
          switch (this.item.type) {
            case 'Run':
              return 'mdi-run';
            case 'Ride':
              return 'mdi-bike';
            case 'NordicSki':
              return 'mdi-ski';
            case 'RollerSki':
              return 'mdi-ski';
            case 'Hike':
              return 'mdi-hiking';
            case 'Yoga':
              return 'self_improvement';
            case 'Workout':
            case 'WeightTraining':
              return 'fitness_center';
          }
        }
        return 'mdi-timer-outline';
      },

        timeComp () {
        if (this.item) {
          return this.getTimeFormatted(this.item.elapsed_time);
        }
        return 0;
      },
      distComp () {
        if (this.item) {
          return Math.trunc(this.item.distance / 100) / 10;
        }
        return 0;
      },
      elevComp () {
        if (this.item) {
          return this.item.total_elevation_gain;
        }
        return 0;
      },
      lapsComp () {
        if (this.item) {
          return JSON.parse(this.item.laps);
        }
        return [];
      },
      stravaLinkComp () {
        if (this.item) {
          return "https://strava.com/activities/" + this.item.strava_id;
        }
        return "#";
      },
      heartrateComp () {
        let heartrate_sum = 0;
        if (this.item) {
          if (this.item.average_heartrate) {
            return this.item.average_heartrate;
          } else {
            if (this.lapsComp) {
              this.lapsComp.forEach((lap) => {
                if (lap.average_heartrate) {
                  heartrate_sum += lap.average_heartrate * lap.elapsed_time;
                }
              });
            }
          }
        }
        return heartrate_sum > 0 ? Math.trunc(heartrate_sum / this.item.elapsed_time) : "-";
      }
    },
    methods: {
      ...mapActions('confirmer', [
        'confirmerAsk'
      ]),
      ...mapActions('snackbar', [
        'snackbarOpen'
      ]),
      getTimeFormatted (seconds, hours = true) {
        let h = 0;
        let m = 0;
        let s = 0;
        if (this.item) {
          if ((hours) && (seconds >= 3600)) {
            h = Math.trunc(seconds / 3600);
          }
          if (seconds >= 60) {
            m = Math.trunc((seconds - h * 3600) / 60);
          }
          s = Math.trunc(seconds - h * 3600 - m * 60);
        }
        //return (h > 0 ? h + ' ч. ' : '') + (h > 0 || m > 0 ? m + ' мин. ' : '') + (h === 0 ? s + ' сек. ' : '');
        return (hours ? (h + ':') : '') + (m > 9 ? m : ('0' + m)) + ':' + (s > 9 ? s : ('0' + s))
      },
      deleteMove(move) {
        this.confirmerAsk({
          title: 'Подтверждение',
          body: 'Вы действительно хотите удалить тренировку?'
        })
          .then(confirmation => {
            if (confirmation) {
              this.isDeletingMove = true;
              axios({
                url: '/api/move/' + move.id,
                method: 'DELETE'
              })
                .then(
                  (res) => {
                    this.snackbarOpen({
                      color: "success",
                      contentColor: "white",
                      content: "Тренировка удалена"
                    });
                    this.$emit('deleteMove', res.data);
                  })
                .catch(
                  () => {
                    this.snackbarOpen({
                      color: "error",
                      contentColor: "white",
                      content: "Ошибка при удалении"
                    });
                  })
                .finally(
                  () => {
                    this.isDeletingMove = false;
                  })
            }
          })
      },
      toggleHide (move) {
        this.isHidingMove = true;
        axios({
          url: '/api/move/' + move.id + '/hide',
          method: 'PUT',
        })
          .then(
            () => {
              this.snackbarOpen({
                color: "success",
                contentColor: "white",
                content: move.hidden_for_school ? "Тренировка открыта" : "Тренировка скрыта"
              });
              move.hidden_for_school = !move.hidden_for_school;
            })
          .catch(
            () => {
              this.snackbarOpen({
                color: "error",
                contentColor: "white",
                content: "Ошибка"
              });
            })
          .finally(
            () => {
              this.isHidingMove = false;
            })
      }
    }
  }
</script>

<style scoped lang="scss">
  .app-calendar-item {
    position: relative;

    .app-calendar-item-default {
      border: 1px solid var(--v-secondary-darken2);
    }

    .app-calendar-item-laps {
      z-index: inherit;

      .v-expansion-panel-header {
        min-height: 20px;
      }
      .v-data-table {
        min-width: 250px;
        margin: 0 -24px;
        border: 1px solid #ccc;
        position: relative;
        z-index: 1;

        td, th {
          padding: 0 4px;
          height: auto;
          text-align: center!important;
        }
        th {
          padding: 2px 4px;
        }
        tbody {
          tr:nth-of-type(2n + 1) {
            background: #f0f0f0;
          }
        }
      }
    }
  }
  .app-calendar-item-hidden {
    position: absolute;
    top: 2px;
    left: 2px;
  }
</style>
