import { render, staticRenderFns } from "./AppPagePrimary.vue?vue&type=template&id=5d9b406a&scoped=true&"
import script from "./AppPagePrimary.vue?vue&type=script&lang=js&"
export * from "./AppPagePrimary.vue?vue&type=script&lang=js&"
import style0 from "./AppPagePrimary.vue?vue&type=style&index=0&id=5d9b406a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d9b406a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VContainer,VProgressLinear})
