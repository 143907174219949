<template>
  <div class="app-calendar-row justify-space-between d-flex">
    <app-calendar-col
      v-for="(item, key) in dataComp"
      :key="key"
      :moves="item.moves"
      :plans="item.plans"
      :user-id="userId"
      :enable-laps="enableLaps"
      :enable-school-functions="enableSchoolFunctions"
      :date="item.date"
      :show-plans="showPlans"
      :show-moves="showMoves"
      @addPlan="handleAddPlan"
      @deleteMove="handleDeleteMove"
    >
      <template #header>
        <div
          v-if="current"
          class="d-flex align-center"
        >
          <v-icon
            v-if="item.date.clone().tz($moment.tz.guess()) <= $moment().tz($moment.tz.guess()) && item.date.clone().tz($moment.tz.guess()).add(1, 'days') > $moment().tz($moment.tz.guess())"
            color="secondary"
            small
          >
            mdi-chevron-right
          </v-icon>
          <span class="font-weight-bold text--black">{{ item.date.clone().tz($moment.tz.guess()).format('DD MMM') }}</span>
        </div>
        <div
          v-else
          class="d-flex align-center"
        >
          {{ item.date.clone().tz($moment.tz.guess()).format('DD MMM') }}
        </div>
      </template>
    </app-calendar-col>
    <app-calendar-summary
      :sum-values="sumValues"
    >
      <template #header>
        <span>Сводка</span>
      </template>
    </app-calendar-summary>
  </div>
</template>

<script>
  import AppCalendarCol from "./AppCalendarCol";
  import AppCalendarSummary from "./AppCalendarSummary";

  export default {
    name: "AppCalendarRow",
    components: {
      AppCalendarCol,
      AppCalendarSummary,
    },
    props: {
      userId: {
        type: [Number],
        default: function () {
          return null;
        }
      },
      startDate: {
        type: [Object, Number],
        default: function () {
          return this.$moment().tz(this.$moment.tz.guess()).startOf('isoweek').tz('UTC');
        }
      },
      plans: {
        type: [Array],
        default: function () {
          return [];
        }
      },
      moves: {
        type: [Array],
        default: function () {
          return [];
        }
      },
      enableSchoolFunctions: {
        type: [Boolean],
        default: false
      },
      enableLaps: {
        type: [Boolean],
        default: false
      },
      showPlans: {
        type: [Boolean],
        default: true
      },
      showMoves: {
        type: [Boolean],
        default: true
      },
      current: {
        type: [Boolean],
        default: false
      },
    },
    data () {
      return {
      }
    },
    computed: {
      dataComp () {
        let date = this.startDate.clone();
        let result = [
          {
            date: date,
            plans: [],
            moves: []
          },
          {
            date: date.clone().add(1, 'days'),
            plans: [],
            moves: []
          },
          {
            date: date.clone().add(2, 'days'),
            plans: [],
            moves: []
          },
          {
            date: date.clone().add(3, 'days'),
            plans: [],
            moves: []
          },
          {
            date: date.clone().add(4, 'days'),
            plans: [],
            moves: []
          },
          {
            date: date.clone().add(5, 'days'),
            plans: [],
            moves: []
          },
          {
            date: date.clone().add(6, 'days'),
            plans: [],
            moves: []
          },
        ];
        // несколько костыльный перевод дат, нужно потимизировать
        // просто запутался и сделал как попало, но работает
        // План задается по 00:00 UTC, но дата сравнивается с 00:00 по текущей временной зоны, переводом в строку без учета зон
        for (let item of this.plans) {
          let start = this.$moment.tz(item.start, "UTC");
          for (let res of result) {
            if (res.date.clone().tz(this.$moment.tz.guess()).format("DD MMM YYYY") === start.format("DD MMM YYYY")) {
              res.plans.push(item);
              break;
            }
          }
        }
        // из таблицы берется время по UTC
        // сравнивается в началом и концом дня с учетом часового пояса
        for (let item of this.moves) {
          let start = this.$moment.tz(item.start_date, "UTC");
          start = start.clone().tz(this.$moment.tz.guess());
          for (let res of result) {
            if (res.date <= start && res.date.clone().add(1, 'days') > start) {
              res.moves.push(item);
              break;
            }
          }
        }
        return result;
      },
      sumValues () {
        let result = {
          runDistance: 0,
          bikeDistance: 0,
          hikingDistance: 0,
          skiDistance: 0,
          runClimb: 0,
          bikeClimb: 0,
          hikingClimb: 0,
          skiClimb: 0,
          runTime: 0,
          bikeTime: 0,
          hikingTime: 0,
          skiTime: 0,
          yogaTime: 0,
          workoutTime: 0,
          allTime: 0,
        };
        // ограничение по первому и последнему дню
        // так как из таблицы для каждой недели выгружаются 1 предыдущее ВС и следующий ПН
        // типа костыль, можно позже сделать красиво
        let firstDay = this.dataComp[0].date;
        let lastDay = firstDay.clone().add(7, 'days');
        this.moves.forEach((move) => {
          let start = this.$moment.tz(move.start_date, "UTC");
          if (!(start < lastDay && start >= firstDay)) {
            return;
          }
          result.allTime += move.elapsed_time;
          switch (move.type) {
            case 'Run':
              result.runClimb += move.total_elevation_gain;
              result.runDistance += move.distance;
              result.runTime += move.elapsed_time;
              break;
            case 'Ride':
              result.bikeClimb += move.total_elevation_gain;
              result.bikeDistance +=move.distance;
              result.bikeTime += move.elapsed_time;
              break;
            case 'NordicSki':
            case 'RollerSki':
              result.skiClimb += move.total_elevation_gain;
              result.skiDistance +=move.distance;
              result.skiTime += move.elapsed_time;
              break;
            case 'Hike':
              result.hikingClimb += move.total_elevation_gain;
              result.hikingDistance +=move.distance;
              result.hikingTime += move.elapsed_time;
              break;
            case 'Yoga':
              result.yogaTime += move.elapsed_time;
              break;
            case 'Workout':
            case 'WeightTraining':
              result.workoutTime += move.elapsed_time;
              break;
          }
        });

        return result;
      }
    },
    mounted () {

    },
    methods: {
      handleAddPlan (plan) {
        this.$emit('addPlan', plan);
      },
      handleDeleteMove (move) {
        this.$emit('deleteMove', move);
      },
    }
  }
</script>

<style lang="scss">
  .app-calendar-row {
    min-width: 1200px;
  }
  .app-calendar-row-past {
    .app-calendar-col-header {
      background: var(--v-primary-lighten5);
    }
    .app-calendar-summary-header {
      background: var(--v-primary-lighten5);
    }
  }
</style>
