<template>
  <v-container
    fluid
    class="app-page app-page-primary pa-0 pa-md-3 d-flex flex-column h-100"
    :class="limitedHeight ? '' : 'overflow-y-auto'"
  >
    <div
      class="app-page-progress-container pb-1"
    >
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
        class="app-page-progress-bar"
      />
    </div>
    <div
      class="app-page-header d-flex align-start align-sm-center flex-nowrap pl-1 pr-4 pt-2 pb-1"
    >
      <slot name="header" />
    </div>
    <div
      class="app-page-body"
      :class="bodyOverflow ? '' : 'overflow-hidden'"
    >
      <slot name="default" />
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'AppPagePrimary',
    props: {
      limitedHeight: {
        type: [Boolean],
        default: true
      },
      bodyOverflow: {
        type: [Boolean],
        default: false
      },
      isLoading: {
        type: [Boolean],
        default: false
      }
    },
  }
</script>

<style lang="scss" scoped>
  .app-page {

  }
  .app-page-progress-container {
    position: relative;
    z-index: 1;
  }
  .app-page-progress-bar {
    position: absolute;
    width: 100%;
  }
  .app-page-body {
    height: 100%;
  }
</style>
