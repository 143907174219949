export default {
  data: () => {
    return {
      enableRefresh: true,
    }
  },
  methods: {
    refreshCalendar () {
      this.enableRefresh = false;
      this.calendarRows = [];
      this.addSmth();
      let that = this;
      setTimeout(function () {
        that.enableRefresh = true;
      }, 5000);
    },
    refreshDashboard () {
      this.enableRefresh = false;
      this.users = [];
      this.getUsers();
      let that = this;
      setTimeout(function () {
        that.enableRefresh = true;
      }, 5000);
    },
  }

}
